import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  name: "customers",
  components: {
    swiper,
    swiperSlide
  },
  props: [],
  data() {
    return {
      swiperOption: {
        direction: "horizontal",
        slidesPerView: 3,
        spaceBetween: 24,
        mousewheel: false,
        autoplay: {
          delay: 4000
        },
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          // when window width is >= 600px
          600: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          800: {
            slidesPerView: 2,
            spaceBetween: 24
          },
          // when window width is >= 960px
          960: {
            slidesPerView: 2,
            spaceBetween: 0
          }
        },
        lazy: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "custom"
        }
      },
      items: [
        {
          image: "hotel_ghasr.svg",
          width: 163,
          height: 88
        },
        {
          image: "darokhane_niavaran.svg",
          width: 142,
          height: 97
        },
        {
          image: "clinic.svg",
          width: 56,
          height: 128
        },
        {
          image: "eram_kish.png",
          width: 214,
          height: 118
        },
        {
          image: "flightio.png",
          width: 200,
          height: 63
        },
        {
          image: "homsa.png",
          width: 213,
          height: 53
        },
        {
          image: "samtik.png",
          width: 130,
          height: 95
        }
      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
